<template>
    <div>
        <b-button variant="primary" v-b-modal.add-plugin-modal>
            <span class="text-nowrap">Add Plugin</span>
        </b-button>
        <b-modal id="add-plugin-modal" hide-footer size="md" centered title="Add Plugin" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
                    errorMsg }}</b-alert>
                <p class="margin-for-title">Title*</p>
                <b-form-input class="mb-1" v-model="data.title" placeholder="Example: Drag And Drop" type="text" />
                <p class="margin-for-title">Code*</p>
                <b-form-input class="mb-1" v-model="data.pluginName" placeholder="Example: DragAndDrop" type="text" /> 
                <div class="mt-2">
                    <p class="margin-for-title">Platform*</p>
                    <b-form-group>
                        <div class="d-flex flex-row justify-content-between align-items-center"  :style="{ width: '70%' }">
                            <b-form-radio 
                                v-for="option in Platforms" 
                                :key="option.value" 
                                v-model="data.platform"
                                :value="option.value" 
                                class="formRadio">
                                {{ option.label }}
                            </b-form-radio>
                        </div>
                    </b-form-group> 
                </div>  

                <template>
                    <p class="margin-for-title"> Path*</p>
                    <b-form-input class="mb-1" v-model="data.path" placeholder="Plugin path" type="text" :disabled="data.platform == 'Mobile'"/>
                </template>
                <div class="modal-footer">
                    <b-button @click="addPlugin" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Add Plugin
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BOverlay,
    BFormGroup,
    BTableSimple,
    BThead,
    BFormSelect,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BSpinner,
    BAlert,
    BFormRadioGroup,
    BFormRadio,

} from "bootstrap-vue";
import { BBreadcrumb } from "bootstrap-vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
    components: {
        BBreadcrumb,
        BAlert,
        BCard,
        BOverlay,
        BFormGroup,
        BAvatar,
        BRow,
        BCol,
        BFormSelect,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BTableSimple,
        BThead,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        ToastificationContent,
        DateRangePicker,
        vSelect,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            pluginName: "",
            title: "",
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            path: '',
            hasError:false,
            
            Platforms: [
                { value: "Mobile", label: "Mobile" },
                { value: "Remote Web", label: "Remote Web" },
                { value: "Local Web", label: "Local Web" }
            ],
            data: {
                title: "",
                pluginName: "",
                path: "",
                platform: "Mobile",
            },
            selectedOption: null,
        };
    },

    props: { 
        fetchPlugin: Function, 
    },

    computed: {
        
    },
  watch: {
        'data.platform'(val){ 
        if(val == 'Mobile'){
        this.$set(this.data, 'path', '');
      }
     }

    },  
    methods: {
        
    validateData() { 
      this.errorMsg = '';
      this.hasError = false;
      const trimmedTitle = this.data.title.trim();
      
      if (!trimmedTitle) {
        this.errorMsg = 'Title cannot be empty or just spaces';
        this.hasError = true;
        return false;
      }
 
      this.data.title = trimmedTitle;
      const trimmedPluginName = this.data.pluginName.trim();
 
      if (!trimmedPluginName) {
        this.errorMsg = 'Please fill all required fields';
        this.hasError = true;
        return false;
      }

      if (trimmedPluginName.includes(' ')) {
        this.errorMsg = 'Spaces are not allowed in the code';
        this.hasError = true;
        return false;
      }
      this.data.pluginName = trimmedPluginName

      if (this.data.platform != 'Mobile' && !this.data.path.trim()) {
        this.errorMsg = 'Please fill all required fields';
        this.hasError = true;
        return false;
      } 

      this.data.path = this.data.path.trim()
      return true;
    },

        async addPlugin() {

         let isValidated= this.validateData()

            this.loading = true
            if(isValidated){
                try {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}/plugin/save-plugin`,  
                    this.data,  
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("_t")}`,  
                        },
                    }
                );

                if (response.data.success) {
                    this.alertMessageToast = response.data.message || 'Data saved successfully';
                    this.showToast("success");
                    this.$emit('fetchPlugin');

                } else {
                    this.alertMessageToast = 'Error saving data!';
                    this.showToast("danger");
                }
            } catch (error) {

                if (error.response && error.response.status == 422) {
                this.alertMessageToast =  error.response.data.message || 'validation error';
                this.showToast("danger");
            } else{
                this.alertMessageToast = 'An error occurred while saving data!';
                this.showToast("danger");
            }

            } finally {
                this.loading = false;
                this.formData = {
                    title: "",
                    pluginName: "",
                    path: "",
                    platform: 'Mobile',
                };
                this.$bvModal.hide('add-plugin-modal');
                // this.$emit('fetchPlugin');
            }
            } else{
                this.loading = false
            }
            
        },

        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },

        clearErrorMsg() {
            this.errorMsg = "";
            this.hasError = false;
        },
        resetModal() {
            // console.log('modal reset')
            this.data = {
                title: "",
                pluginName: "",
                path: "",
                platform: 'Mobile',
            }
            this.errorMsg = '';
            this.hasError = false;

        },


    },

};
</script>


<style>
.vs__selected,
.vs__search {
    color: #6e6b7b;
    line-height: 2.0 !important;
}



[dir] .custom-control-input:checked~.custom-control-label::before {
    border-color: #32aaed;
    border-width: 5px;
    background-color: unset; 
}


.margin-for-title{
    margin-bottom:3px;
}

[dir] .modal-footer { 
    border-top: unset
}

.custom-radio .custom-control-label {
    font-size: 13.5px;
    line-height: 21px; 
}

</style>